<template>
  <div>
    <div class="container-fluid">
      <PageTitle :noAdd="true" />
      <div class="tab-content">
        <div class="tab-pane fade show active" id="main" role="tabpanel">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title mb-0">{{pageTitle}} Form</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-8">
                </div>
                <div class="col-md-4">
                  <form @submit.prevent="doFilter(),doSearch()" v-if="total_data>0">
                    <div class="form-group mb-0">
                      <div class="input-group">
                        <input v-model="filter.search" type="text" class="form-control"
                          placeholder="Type and Press Enter" required />
                        <div class="input-group-append">
                          <button class="btn btn-info" type="submit"><i class="fas fa-search"></i></button>
                        </div>
                        <button v-if="isSearch" @click="doReset(),clsSearch()" type="button" class="btn btn-danger ml-1">
                          <i class="fa fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped product-overview">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Full Name</th>
                    <th>Email Address</th>
                    <th>Subject</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="data.data===false">
                    <td colspan="99" class="text-center">
                      <Loader class="mt10 mb10" />
                    </td>
                  </tr>
                  <tr v-for="(v,k) in data.data" :key="k">
                    <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>

                    <td>{{ v.sc_name }}</td>
                    <td>{{ v.sc_email }}</td>
                    <td>{{ v.sc_subject }}</td>
                    <td>{{ (v.sc_create_date).dates('format2') }}</td>
                    <td>
                      <label v-if="v.sc_is_active == 'D'" class="label label-info">Unread</label>
                      <label v-if="v.sc_is_active == 'R'" class="label label-success">Read</label>
                    </td>
                    <td>
                      <a @click.prevent="setModal(v)" style="cursor: pointer;" class="text-inverse icon_action act_icon"
                        v-tooltip="'Show Details'">
                        <i class="ti-eye"></i>
                      </a>

                      <VModalVue :open.sync="isModalOpen" :inlineBackdrop="false" :title="'Detail Contact Form'">
                        <template v-slot:title>
                          <h4 class="m-0">Detail Contact Form</h4>
                        </template>

                        <template v-slot:body>
                          <div class="modal-body">
                            <div class="row">
                              <div class="col-lg-12 mt-2">
                                <div class="form-group">
                                  <label class="control-label">Full Name</label>
                                  <p>{{ dataModal.sc_name }}</p>
                                </div>
                              </div>
                              <div class="col-lg-12 mt-2">
                                <div class="form-group">
                                  <label class="control-label">Email Address</label>
                                  <p>{{ dataModal.sc_email }}</p>
                                </div>
                              </div>
                              <div class="col-lg-12 mt-2">
                                <div class="form-group">
                                  <label class="control-label">Phone Number</label>
                                  <p>{{ dataModal.sc_phone }}</p>
                                </div>
                              </div>
                              <div class="col-lg-12 mt-2">
                                <div class="form-group">
                                  <label class="control-label">Subject</label>
                                  <p>{{ dataModal.sc_subject }}</p>
                                </div>
                              </div>
                              <div class="col-lg-12 mt-2">
                                <div class="form-group">
                                  <label class="control-label">Message</label>
                                  <div v-html="dataModal.sc_message"></div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </template>
                      </VModalVue>
                    </td>
                  </tr>
                  <tr v-if="notFound">
                    <td colspan="99" class="text-center">
                      <h4 class="mt-1">{{'Data not found'}}</h4>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer" v-if="data.data!==false&&data.data.length">
              <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                  <div class="pagination-flat float-right">
                    <Pagination :data="data" :limit="2" @pagination-change-page="doPaginate">
                      <span slot="prev-nav"><i class="icon-arrow-left"></i></span>
                      <span slot="next-nav"><i class="icon-arrow-right"></i></span>
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import GlobalVue from '@libs/Global'
  import PageTitle from '@view/include/PageTitle'
  import VModalVue from "../components/VModal.vue"
  import Gen from '@libs/Gen.js'

  let _ = global._

  export default {
    extends: GlobalVue,
    components: {
      PageTitle,
      VModalVue
    },
    data() {
      return {
        idKey: 'sc_id',
        other_options: {
          menubar: false
        },
        dataModal: {},
        isModalOpen: false,
        isSearch: false,
        total_data: '',
      }
    },
    mounted() {
      this.apiGet()
      if(this.filter.search) this.isSearch = true
    },
    methods: {
      doSearch (){
        this.isSearch = true
      },
      clsSearch(){
        this.isSearch = false
      },
      setModal(data) {
        this.dataModal = data
        Gen.apiRest(
          "/do/"+this.modulePage,
          {data: {
            type: "updateStatus",
            id: data.sc_id
          }},
          "POST"
        )
          .then(() => {
            this.apiGetNoOverlay()
          })
        this.isModalOpen = true
      },
     
      apiGetNoOverlay(params = {}){
        Gen.apiRest(
          "/get/"+this.modulePage+
          (this.pageSlug?"/"+this.pageSlug:"")+
          (this.pageId?"/"+this.pageId:""), 
          {
            params: Object.assign({}, this.apiParams, params.query||{})
          }
        ).then(res=>{
          _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
          })
          
          if(!this.isList){
            this.setFocusForm()
          }
        })
      },
    },
    watch: {
      $route() {
        this.apiGet()
      },
      '$route.params.pageSlug':function(){
        this.$set(this,'filter',{})
      },
    }
  }
</script>

<style> 
.modal-body {
    height: 550px;
    overflow-y: auto;
}
</style>