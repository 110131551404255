<template>
<div v-if="open" :style="'display:'+(open?'block':'none')" class="modal fade show" :class="{inlineBackdrop:inlineBackdrop}" tabindex="-1" >
	<div :class="'modal-dialog '+size">
		<div class="modal-content">
			<div class="modal-header">
				<slot name="header">
					<div class="modal-title"><slot name="title"></slot></div>
					<button type="button" class="close" data-dismiss="modal" @click="$emit('update:open', false)">&times;</button>
				</slot>
			</div>
			<slot name="body"></slot>
		</div>
	</div>
</div>
</template>

<script>
let $ = global.jQuery;
export default {
	name:"VModal",
	props:{
		open:{
			default:false,
			type:Boolean
		},
		size:{
			default:"modal-md",
			type:String
		},
		title:{
			default:"Modal",
			type:String
		},
		inlineBackdrop: {default:false,type:Boolean},
	},
	watch:{
		open(e){
			if(this.inlineBackdrop) return;
			if(e){
				$("body").addClass("modal-open");
				if(!$(".modal-backdrop").length){
					$("body").append("<div class='modal-backdrop fade show'></div>");
				}
			}else{
				setTimeout(()=>{
					if(!$(".modal").length){
						$("body").removeClass("modal-open");
						$(".modal-backdrop").remove();
					}
				}, 10)
			}
		}
	}
}
</script>